import React, { useEffect } from 'react';
import { Root } from './style';

import { Link } from 'gatsby';
import platform from 'platform';

import ContractLinks from 'components/Common/ContractLinks';
import SiteData from 'components/Common/SiteData';

import GooglePlay from 'assets/Index/CallToAction/MobileApp/google-play.png';

import vars from 'variables';

const GetPaid = () => {
  useEffect(() => {
    if (platform.os.family.includes('iOS')) {
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.appendChild(
        document.createTextNode(`
          div.app-stores a.google {
            display: none;
          }
        `)
      );
      head.appendChild(style);
    }
  }, []);

  return (
    <Root>
      <SiteData path="index.getPaid" />
      <div>
        <a
          className="button blue logo"
          href={vars.links.buy}
          target="_blank"
          rel="noreferrer"
        >
          Buy $HODL now
        </a>
        <Link className="button white mobile logo-bnb" to={vars.pages.claim}>
          Claim BNB Rewards
        </Link>
        <ContractLinks />
      </div>
      {/* <div className="app-stores">
        <a
          className="google"
          href="https://play.google.com/store/apps/details?id=net.hodltoken.hodlapp&pcampaignid=web_share"
        >
          <img src={GooglePlay} />
        </a>
      </div> */}
    </Root>
  );
};

export default GetPaid;
