import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`
  a {
    margin-right: 20px;
  }

  a.claim {
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid ${vars.colors.blue};
  }

  ${vars.desktop.mediaQuery} {
    & {
      padding-top: 100px;
    }

    h1 {
      margin: 30px 0 20px -4px;
      font-size: 5.65rem;
    }

    p {
      margin: 30px 0 40px;
      width: 600px;
    }

    a.button.logo-x {
      padding-left: 45px;
      padding-right: 18px;
    }

    a.claim {
      margin-left: 25px;
      font-size: 0.775rem;
    }

    .app-stores {
      margin-top: 60px;
    }

    .app-stores img {
      width: 240px;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      text-align: center;
    }

    h1 {
      font-size: 2rem;
    }

    h1,
    h4,
    p {
      text-align: center;
    }

    p {
      margin: 10px 0 38px;
    }

    br {
      display: none;
    }

    a.button {
      width: 157px;
      margin: 0 auto 22px;
      padding-left: 0;
      padding-right: 0;
      display: table;
    }

    a.button.logo {
      width: calc(157px - 17px);
      padding-left: 17px;
      background-position: 22px center;
      background-size: 16px 16px;
    }

    a.button.logo-x {
      width: calc(157px - 17px);
      padding-left: 17px;
      background-position: 30px center;
      background-size: 16px 16px;
    }

    a.button.logo-bnb {
      width: calc(157px - 17px);
      padding-left: 17px;
      background-position: 10px center;
      background-size: 16px 16px;
    }

    a.button.mobile {
    }

    .app-stores {
      margin-top: 60px;
      margin-bottom: -15px;
    }

    .app-stores a {
      margin: 0;
    }

    .app-stores img {
      width: 160px;
    }
  }
`;

export { Root };
