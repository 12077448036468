import React from 'react';
import { Root } from './style';

import FeaturedBy from './FeaturedBy';
import GetPaid from './GetPaid';
import MobileApp from './MobileApp';

const CallToAction = () => {
  return (
    <Root className="body-width">
      <div className="left">
        <GetPaid />
        <FeaturedBy />
      </div>
      <div className="right">
        <MobileApp />
      </div>
    </Root>
  );
};

export default CallToAction;
