import React from 'react';
import { Root } from './style';

import FeaturedBy from 'components/Common/FeaturedBy';
import HoldingNFTs from 'components/Common/HoldingNFTs';
import JoinTheCommunities from 'components/Common/JoinTheCommunities';
import ReadyToStart from 'components/Common/ReadyToStart';

import Calculator from './Calculator';
import CallToAction from './CallToAction';
import EssentialInfo from './EssentialInfo';
import Exchanges from './Exchanges';
import Features from './Features';
import Roadmap from './Roadmap';
import Stats from './Stats';
import Taxes from './Taxes';

const Index = ({ pageContext: { exchanges } }) => {
  return (
    <Root>
      <CallToAction />
      <Stats />
      <Taxes />
      <Exchanges exchanges={exchanges} />
      <Roadmap />
      <JoinTheCommunities />
      <Features />
      <EssentialInfo />
      {/* <HoldingNFTs showMore={true}/> */}
      <Calculator />
      {/* <FeaturedBy /> */}
      <ReadyToStart />
    </Root>
  );
};

export default Index;
