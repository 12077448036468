import React from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';
import { useSiteData } from 'utils';

import Boxes from 'components/Common/Boxes';
import SiteData from 'components/Common/SiteData';

import vars from 'variables';

const Taxes = () => {
  const { index } = useSiteData().pages;

  const state = useState('buy');
  const select = (category) => state.set(category);

  return (
    <Root className="body-width">
      <div className="taxInfo">
        <SiteData path="index.taxesInfo" />
        <a
          className="button blue logo"
          href={vars.links.buy}
          target="_blank"
          rel="noreferrer"
        >
          Buy $HODL Now
        </a>
      </div>
      <Boxes
        name="buy"
        layout="list"
        className={state.get() === 'buy' ? 'visible' : ''}
        content={index.buyTaxes}
      />
      <Boxes
        name="sell"
        layout="list"
        className={state.get() === 'sell' ? 'visible' : ''}
        content={index.sellTaxes}
      />
      <Boxes
        name="transfer"
        layout="list"
        className={state.get() === 'transfer' ? 'visible' : ''}
        content={index.transferTaxes}
      />
    </Root>
  );
};

export default Taxes;
